import { Logo } from "../logo/Logo";
import {NavBar} from "../nav/NavBar";
import {Flex} from "../common/Flex";

const headerStyle = {
    backgroundColor: "rgba(5, 20, 5, 0.5)",
}

export const Header = () => {
    return (
        <div style={headerStyle}>
            <Flex direction={"row"}>
                <Logo />
                <NavBar />
            </Flex>
        </div>
    )
}