import React from 'react'

type CarouselProps = {
    children: React.ReactNode
}

const Carousel = (props: CarouselProps) => {

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                <div className="carousel-content-wrapper">
                    <div className="carousel-content">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Carousel
