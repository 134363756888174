import {Header} from "../header/Header";
import * as React from "react";
import {Content} from "../content/Content";

export const Home = () => {
    return (
        <>
            <Header />
            <Content />
        </>
    )
}
