type FlexProps = {
    children: React.ReactNode;
    direction: 'row' | 'column';
}

const flexStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
}

export const Flex = (props: FlexProps) => {
    return (
        <div style={{
            ...flexStyle,
            flexDirection: props.direction
        }}>
            {props.children}
        </div>
    )
}