const bannerStyle: React.CSSProperties = {
    width: "100%",
    height: "300px",
    zIndex: "-1",
    backgroundSize: '100% 100%'
}

type BannerProps = {
    backgroundImage: string
}

export const Banner = (props: BannerProps) => {
    return (
        <div style={{
            ...bannerStyle,
            backgroundImage: `url(${props.backgroundImage})`
        }} />
    )
}