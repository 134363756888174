const logoStyle = {
    width: '50px',
    height: '50px',
    margin: '10px',
    opacity: '0.85',
}

export const Logo = () => {
    return (
        <img src={"profits.jpg"} alt="logo" style={logoStyle}/>
    );
}