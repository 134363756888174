type NavButtonProps = {
    destination: string,
    text: string
}

const navButtonStyle = {
    padding: "10px",
    margin: "10px",
    borderRadius: "1px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    cursor: "pointer"
}

export const NavButton = (props: NavButtonProps) => {
    return (
        <a href={props.destination}>
            <div style={navButtonStyle}>
                {props.text}
            </div>
        </a>
    )
}