import { Flex } from "../common/Flex";
import {NavButton} from "./NavButton";

export const NavBar = () => {
    return (
        <Flex direction={"row"}>
            <NavButton destination={"/"} text={"Business"} />
            <NavButton destination={"/about"} text={"Technology"} />
            <NavButton destination={"/contact"} text={"Collaborate"} />
        </Flex>
    )
}