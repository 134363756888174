import {Banner} from "../banner/Banner";
import React from "react";
import Carousel from "../carousel/Carousel";

export const Content = () => {
    return (
        <div>
            <Banner backgroundImage={"handshake.jpeg"}/>
            <Carousel>
                <img src="handshake.jpeg" alt="handshake"/>
                <img src="handshake.jpeg" alt="handshake"/>
                <img src="handshake.jpeg" alt="handshake"/>
            </Carousel>
        </div>
    )
}